import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Explore data",
  "includeToc": false,
  "tags": ["Explore data", "Compare revenue", "Nationwide Federal", "Native American", "Query revenue data", "Downloads & Documentation", "How revenue works", "Federal revenue by company", "biofuel", "biomass", "coal", "copper", "geothermal", "gas", "GOMESA", "Gulf of Mexico", "hydroelectric", "iron", "minerals", "offshore", "oil", "solar", "wind", "wood-derived fuel", "Alaska", "Alabama", "Arkansas", "Arizona", "California", "Colorado", "Connecticut", "Washington, DC", "Delaware", "Florida", "Georgia", "Hawaii", "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky", "Louisiana", "Massachusetts", "Maryland", "Maine", "Michigan", "Minnesota", "Missouri", "Mississippi", "Montana", "North Carolina", "North Dakota", "Nebraska", "New Hampshire", "New Jersey", "New Mexico", "Nevada", "New York", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Virginia", "Vermont", "Washington", "Wisconsin", "West Virginia", "Wyoming"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DataFilterProviderWrapper = makeShortcode("DataFilterProviderWrapper");
const ExploreDataProviderWrapper = makeShortcode("ExploreDataProviderWrapper");
const Container = makeShortcode("Container");
const Box = makeShortcode("Box");
const ExploreData = makeShortcode("ExploreData");
const ContextProvider = makeShortcode("ContextProvider");
const Revenue = makeShortcode("Revenue");
const MapContext = makeShortcode("MapContext");
const RevenueMap = makeShortcode("RevenueMap");
const SummaryCards = makeShortcode("SummaryCards");
const RevenueSummaryTrends = makeShortcode("RevenueSummaryTrends");
const RevenueSummaryTopCommodities = makeShortcode("RevenueSummaryTopCommodities");
const PageSubMenu = makeShortcode("PageSubMenu");
const Grid = makeShortcode("Grid");
const DetailCards = makeShortcode("DetailCards");
const RevenueDetailTrends = makeShortcode("RevenueDetailTrends");
const RevenueDetailCommodities = makeShortcode("RevenueDetailCommodities");
const RevenueDetailTypes = makeShortcode("RevenueDetailTypes");
const RevenueCountyMap = makeShortcode("RevenueCountyMap");
const RevenueOverTime = makeShortcode("RevenueOverTime");
const RevenueNationalSummary = makeShortcode("RevenueNationalSummary");
const RevenueTopLocations = makeShortcode("RevenueTopLocations");
const RevenueByCompany = makeShortcode("RevenueByCompany");
const Disbursements = makeShortcode("Disbursements");
const DisbursementMap = makeShortcode("DisbursementMap");
const DisbursementTrend = makeShortcode("DisbursementTrend");
const DisbursementRecipientSummary = makeShortcode("DisbursementRecipientSummary");
const CompareContext = makeShortcode("CompareContext");
const DisbursementDetailTrends = makeShortcode("DisbursementDetailTrends");
const DisbursementRecipients = makeShortcode("DisbursementRecipients");
const DisbursementSources = makeShortcode("DisbursementSources");
const DisbursementsOverTime = makeShortcode("DisbursementsOverTime");
const NationwideDisbursementSummary = makeShortcode("NationwideDisbursementSummary");
const DisbursementTopRecipients = makeShortcode("DisbursementTopRecipients");
const Production = makeShortcode("Production");
const ProductionMap = makeShortcode("ProductionMap");
const ProductionSummaryTrends = makeShortcode("ProductionSummaryTrends");
const ProductionDetailTrends = makeShortcode("ProductionDetailTrends");
const ProductionLandCategory = makeShortcode("ProductionLandCategory");
const ProductionTopLocations = makeShortcode("ProductionTopLocations");
const ProductionCountyMap = makeShortcode("ProductionCountyMap");
const ProductionOverTime = makeShortcode("ProductionOverTime");
const Typography = makeShortcode("Typography");
const ProductionLocationTotal = makeShortcode("ProductionLocationTotal");
const QueryLink = makeShortcode("QueryLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <DataFilterProviderWrapper defaults='explore_data_filter_default' mdxType="DataFilterProviderWrapper">
  <ExploreDataProviderWrapper mdxType="ExploreDataProviderWrapper">
    <Container style={{
          padding: 0
        }} maxWidth={false} mdxType="Container">
      <Box style={{
            background: 'white'
          }} mdxType="Box">
        <ExploreData mdxType="ExploreData">
          <ContextProvider params={'?dataType=Revenue'} mdxType="ContextProvider">
            <Revenue mdxType="Revenue">
              <MapContext mdxType="MapContext">
                <RevenueMap mdxType="RevenueMap" />
                <SummaryCards mdxType="SummaryCards">
                  <RevenueSummaryTrends mdxType="RevenueSummaryTrends" />
                  <RevenueSummaryTopCommodities mdxType="RevenueSummaryTopCommodities" />
                </SummaryCards>
              </MapContext>
              <Box style={{
                    position: 'relative',
                    background: 'white',
                    zIndex: 250,
                    paddingBottom: 50
                  }} mdxType="Box">
                <Box name="exploreDataContent" className="explore-data-content" mdxType="Box">
                  <PageSubMenu menuItems={['Compare revenue', 'Nationwide revenue summary', 'Top nationwide locations', 'Federal revenue by company']} mdxType="PageSubMenu">
                    <Container id="compare-revenue" mdxType="Container">
                      <Grid container mdxType="Grid">
                        <Grid item md={12} mdxType="Grid">
                          <Box color="secondary.main" mt={5} mb={2} borderBottom={5} mdxType="Box">
                            <Box component="h2" color="secondary.dark" mdxType="Box">Compare revenue</Box>
                          </Box>
                          <Box fontSize="body1.fontSize" mdxType="Box">
                            Add more than one location to compare.  Select states, counties, and offshore regions.
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container style={{
                            overflowX: 'auto'
                          }} mdxType="Grid">
                        <Grid item md={12} mdxType="Grid">
                          <DetailCards mdxType="DetailCards">
                            <RevenueDetailTrends mdxType="RevenueDetailTrends" />
                            <RevenueDetailCommodities mdxType="RevenueDetailCommodities" />
                            <RevenueDetailTypes mdxType="RevenueDetailTypes" />
                            <RevenueCountyMap mdxType="RevenueCountyMap" /> 
                          </DetailCards>
                        </Grid>
                      </Grid>
                    </Container>
                    <RevenueOverTime title="Revenue over time" mdxType="RevenueOverTime" />        
                    <RevenueNationalSummary title="Nationwide revenue summary" mdxType="RevenueNationalSummary" />
                    <RevenueTopLocations title="Top nationwide locations" mdxType="RevenueTopLocations" />
                    <RevenueByCompany title="Federal revenue by company" mdxType="RevenueByCompany" />
                  </PageSubMenu>
                </Box>
              </Box>
            </Revenue>
          </ContextProvider>
          <ContextProvider params={'?dataType=Disbursements'} mdxType="ContextProvider">
            <Disbursements mdxType="Disbursements">
              <MapContext mdxType="MapContext">
                <DisbursementMap mdxType="DisbursementMap" />
                <SummaryCards mdxType="SummaryCards">
                  <DisbursementTrend mdxType="DisbursementTrend" />
                  <DisbursementRecipientSummary mdxType="DisbursementRecipientSummary" />
                </SummaryCards>
              </MapContext>
              <Box style={{
                    position: 'relative',
                    background: 'white',
                    zIndex: 250,
                    paddingBottom: 50
                  }} mdxType="Box">
                <Box name="exploreDataContent" className="explore-data-content" mdxType="Box">
                  <PageSubMenu menuItems={['Compare disbursements', 'Nationwide disbursements summary', 'Top nationwide recipients']} mdxType="PageSubMenu">
                    <CompareContext mdxType="CompareContext">
                      <Container id="compare-disbursements" mdxType="Container">
                        <Grid container mdxType="Grid">
                          <Grid item md={12} mdxType="Grid">
                            <Box color="secondary.main" mb={1} borderBottom={5} mdxType="Box">
                              <Box component="h2" color="secondary.dark" mdxType="Box">Compare disbursements</Box>
                            </Box>
                            <Box fontSize="body1.fontSize" mdxType="Box">
                              Add more than one location to compare.  Select states, counties, and offshore regions.
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container mdxType="Grid">
                          <Grid item md={12} mdxType="Grid">
                            <DetailCards mdxType="DetailCards">
                              <DisbursementDetailTrends mdxType="DisbursementDetailTrends" />
                              <DisbursementRecipients mdxType="DisbursementRecipients" />
                              <DisbursementSources mdxType="DisbursementSources" />  
                            </DetailCards>
                          </Grid>
                        </Grid>
                      </Container>
                      <DisbursementsOverTime title="Disbursements over time" mdxType="DisbursementsOverTime" />   
                    </CompareContext>         
                    <NationwideDisbursementSummary title="Nationwide disbursements summary" mdxType="NationwideDisbursementSummary" />
                    <DisbursementTopRecipients title="Top nationwide recipients" mdxType="DisbursementTopRecipients" />
                  </PageSubMenu>
                </Box>
              </Box>
            </Disbursements>
          </ContextProvider>
          <ContextProvider params={'?dataType=Production'} mdxType="ContextProvider">
            <Production mdxType="Production">
              <MapContext mdxType="MapContext">
                <ProductionMap mdxType="ProductionMap" />
                <SummaryCards mdxType="SummaryCards">
                  <ProductionSummaryTrends mdxType="ProductionSummaryTrends" /> 
                </SummaryCards>
              </MapContext>
              <Box style={{
                    position: 'relative',
                    background: 'white',
                    zIndex: 250,
                    paddingBottom: 50
                  }} mdxType="Box">
                <Box name="exploreDataContent" className="explore-data-content" mdxType="Box">
                  <PageSubMenu menuItems={['Compare production', 'Nationwide production summary', 'Top nationwide locations']} mdxType="PageSubMenu">
                    <CompareContext mdxType="CompareContext">
                      <Container id="compare-production" mdxType="Container">
                        <Grid container mdxType="Grid">
                          <Grid item md={12} mdxType="Grid">
                            <Box color="secondary.main" mb={2} borderBottom={5} mdxType="Box">
                              <Box component="h2" color="secondary.dark" mdxType="Box">Compare production</Box>
                            </Box>
                            <Box fontSize="body1.fontSize" mdxType="Box">
                              Add more than one location to compare.  Select states, counties, and offshore regions.
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container mdxType="Grid">
                          <Grid item md={12} mdxType="Grid">
                            <DetailCards mdxType="DetailCards">     
                              <ProductionDetailTrends mdxType="ProductionDetailTrends" />
                              <ProductionLandCategory title="Production over time" mdxType="ProductionLandCategory" />        
                              <ProductionTopLocations title="Top locations" showQueryLink mdxType="ProductionTopLocations" />   
                              <ProductionCountyMap mdxType="ProductionCountyMap" />
                            </DetailCards>
                          </Grid>
                        </Grid>
                      </Container>       
                      <ProductionOverTime title="Production over time" mdxType="ProductionOverTime" />
                    </CompareContext>
                    <Container id="nationwide-production-summary" mdxType="Container">
                        <Grid container mdxType="Grid">
                          <Grid item md={12} mdxType="Grid">
                            <Box mt={10} mb={1} color="secondary.main" borderBottom={5} mdxType="Box">
                              <Box component="h2" color="secondary.dark" mdxType="Box">Nationwide production summary</Box>
                            </Box>
                            <Typography variant="body1" mdxType="Typography">
                              <ProductionLocationTotal mdxType="ProductionLocationTotal" />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Container>
                    <Container id="top-nationwide-locations" mdxType="Container">
                      <Grid container mdxType="Grid">
                        <Grid item md={12} mdxType="Grid">
                          <Box color="secondary.main" mt={5} mb={2} borderBottom={2} display="flex" justifyContent="space-between" mdxType="Box">
                            <Box component="h3" color="secondary.dark" display="inline" mdxType="Box">Top nationwide locations</Box>
                            <Box display={{
                                  xs: 'none',
                                  sm: 'inline'
                                }} align="right" position="relative" top={5} mdxType="Box">
                              <QueryLink groupBy="stateOffshoreName" linkType="FilterTable" landType="Federal Offshore,Federal Onshore,Mixed Exploratory" {...props} mdxType="QueryLink">
                                Query production by location
                              </QueryLink>
                            </Box>
                          </Box>
                          <Box display={{
                                xs: 'block',
                                sm: 'none'
                              }} align="left" mdxType="Box">
                            <QueryLink groupBy="stateOffshoreName" linkType="FilterTable" landType="Federal Offshore,Federal Onshore,Mixed Exploratory" {...props} mdxType="QueryLink">
                              Query production by location
                            </QueryLink>
                          </Box>           
                          <ProductionTopLocations horizontal mdxType="ProductionTopLocations" />
                        </Grid>
                      </Grid>
                    </Container>
                  </PageSubMenu>
                </Box>
              </Box>
            </Production>
          </ContextProvider>
        </ExploreData>
      </Box>
    </Container>
  </ExploreDataProviderWrapper>
    </DataFilterProviderWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      